import axios from 'axios'

import {encodeLoad, decodeLoad} from '@/lib/base64.js'

import {CreateHypatiaJWE} from '@/lib/createJWT'

import {useAdminStore} from '@/stores/adminstore.js'
import { useUserStore } from '@/stores/userstore.js'

import Compressor from 'compressorjs'

import {base64} from 'base64-url'

// import { jwt } from 'jsonwebtoken'

export const imageActions = {
  async savePictureFromFile(fileObject) {
    return new Promise(resolve => {
      let self = this
      console.log('*** SAVED GOT CALLED ! (%j)', fileObject)
      const reader = new FileReader()
      reader.readAsDataURL(fileObject)
      console.log(reader)
      reader.onloadend = function () {
        let imageSrc = reader.result
        new Compressor(dataURItoBlob(imageSrc), {
          quality: 0.6,
          mimeType: 'image/webp',
          convertTypes: ['image/png', 'image/webp'],
          success(cimg) {
            console.log('===== COMPRESSOR SUCCESS')
            console.log(cimg)
            //  console.log(imgToSave)
            let filer = new FileReader()
            filer.readAsDataURL(cimg) // converts the blob to base64 and calls onload
            filer.onloadend = function () {
              let imgToSaveCompressed = filer.result
              console.log(imgToSaveCompressed)
              self.saveImage(imgToSaveCompressed).then((result) => {
                console.log('SAVE IMAGE RETURNED (%j)', result)
                if (result.success) {
                  resolve({
                    success: true,
                    data: result.data
                  })
                }
              })
            }
          },
          error(err) {
            console.error(err)
          }
        }) // compressor
      }
    }) // Promise
  },
  async saveImageFromFile(fileObject) {
    console.log('Save saveImageFromFile got called ')
    return new Promise((resolve) => {
      let payload = {
        fileName: fileObject.name || this.primaryMode.displayName.trim().replace(' ', '_'),
        mimeType: fileObject.type || 'image/webp' //this.mimeType
      }
      this.setImageProperties7(payload)
      this.savePictureFromFile(fileObject).then((result) => {
        console.log('RESULT FROM SAVE PICTURE')
        console.log(result)
        if (result.success) {
          //this.primaryModel.picture = result.data.storeName;
          resolve({
            success: true,
            storeName: result.data.storeName
          })
        //   this.isSaving = false;
        } else { // savePrimary
          resolve({
          success: false,
          error: true
          })
        }
      }) // savePictureFromFile
    })// promise
  },
  async saveImage(imageToSave) {
    console.log('Save Image got called ')
    console.log(imageToSave)
    const adminStore = useAdminStore()
    return new Promise((resolve) => {
      let imageByteArray = convertDataURIToBinary(imageToSave)
      let formData = new FormData()
      // console.log('IMAGE BYTE ARRAY (%s)', imageByteArray)
      // console.log(imageByteArray)
      //      let imageBlob = new Blob([imageByteArray], { type: this.mimeType })
      let imageBlob = new Blob([imageByteArray], {type: this.mimeType})
      console.log('IMAGE BLOB')
      console.log(imageBlob)
      formData.append('files', imageBlob, this.fileName)
      formData.append('gogl', true)
      formData.append('p1', adminStore.sid)
      console.log('FORM DATA (%j)', formData)
      adminStore
        .getAxiosConnectionImage('imageupload/')
        .post('upload', formData)
        .then((response) => {
          console.log('Success!')
          console.log({response})
          let responseData = response.data || {}
          if (responseData.success) {
            let uploaded = responseData.uploaded || []
            if (uploaded.length !== 1) {
              console.error('*** ERROR *** more than one picture was uploaded...')
              resolve({
                success: false,
                error: true,
                errorMsg: '*** ERROR *** more than one picture was uploaded...'
              })
            }

            resolve({
              success: true,
              data: uploaded[0]
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
   async deleteImage(image, library) {
    console.log('DELETE Image got called (%s)', image.image_imageurl)
    console.log(image)
    const adminStore = useAdminStore()
    let pLoad = {
        fileName: encodeLoad(image.image_imageurl),
        p1: adminStore.sid,
        gogl: true
     }
     return new Promise((resolve) => {
       adminStore
       .getAxiosConnection('imageupload/')
         .post('delete', pLoad)
         .then((response) => {
           console.log('DELETE IMAGE RETURNED (%j)', response)
           let responseData = response.data || {}
          if (responseData.success) {
             this.removeImageFromLibrary(image, library)
               .then(() => {
                  resolve({
                    success: true
                  })
                })
          } else {
            resolve({
              success: false,
              error: true,
              errorMsg:'Could not delete image '
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async removeImageFromLibrary(image, library) {
      console.log('removeImageFromLibrary got called !')
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pUser = this.primaryuser
      console.log('image Uid (%s)', image.uid)
      console.log('library Uid (%s)', library.uid)
      let deleteLoad = `<${image.uid}> * * . \n` + `<${library.uid}> <il_hasImages> <${image.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      adminStore.getAxiosConnection('dgraph-api/')
        .post('ddeletenquad/imagetolibrary', pLoad)
        .then((result) => {
          console.log('delete image from library returned ', result)
          this.getPictureGalleries().then((res) => {
              this.activepicturegallery = res.data
            resolve({
              success: true,
              data: res.data
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete primary had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },

  async removeImageLibrary(library) {
      console.log('removeImageFromLibrary got called !')
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      const userStore = useUserStore()
      let pUser = userStore.primaryuser
      console.log('pUser Uid (%s)', pUser.uid)
      console.log('library Uid (%s)', library.uid)
      let deleteLoad = `<${library.uid}> * * . \n` + `<${pUser.uid}> <pu_hasImageLibrary> <${library.uid}> . \n`
      let pLoad = {
        mutate: encodeLoad(deleteLoad),
        p1: adminStore.sid,
        gogl: true
      }
      adminStore.getAxiosConnection('dgraph-api/')
        .post('ddeletenquad/imagetolibrary', pLoad)
        .then((result) => {
          console.log('delete image from library returned ', result)
          this.getPictureGalleries().then((res) => {
              this.activepicturegallery = res.data
            resolve({
              success: true,
              data: res.data
            })
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete primary had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async saveVideo(videoToSave) {
    console.log('Save Video got called ')
    console.log(videoToSave)
    const adminStore = useAdminStore()
    return new Promise((resolve) => {
      let imageByteArray = convertDataURIToBinary(videoToSave)
      let formData = new FormData()
      // console.log('IMAGE BYTE ARRAY (%s)', imageByteArray)
      // console.log(imageByteArray)
      //      let imageBlob = new Blob([imageByteArray], { type: this.mimeType })
      let imageBlob = new Blob([imageByteArray], {type: this.videoMimeType})
      console.log('IMAGE BLOB')
      console.log(imageBlob)
      formData.append('files', imageBlob, this.videoFileName)
      formData.append('gogl', true)
      formData.append('p1', adminStore.sid)
      console.log('FORM DATA (%j)', formData)
      adminStore
        .getAxiosConnectionImage('imageupload/')
        .post('upload', formData)
        .then((response) => {
          console.log('Success!')
          console.log({response})
          let responseData = response.data || {}
          if (responseData.success) {
            let uploaded = responseData.uploaded || []
            if (uploaded.length !== 1) {
              console.error('*** ERROR *** more than one picture was uploaded...')
              resolve({
                success: false,
                error: true,
                errorMsg: '*** ERROR *** more than one picture was uploaded...'
              })
            }

            resolve({
              success: true,
              data: uploaded[0]
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async newImageLibrary(payload) {
    console.log('newImageLibrary ')
    console.log(payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance
        .post('createImageLibrary/newImageLibrary', pLoad)
        .then((response) => {
          console.log('createImageLibrary returned!')
          console.log(response)
          let responseData = response.data || {}
          if (responseData.success) {
            resolve({
              success: true,
              data: response.data
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async addImageToLibrary(payload) {
    console.log('addImageToLibrary ')
    console.log(payload)
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(payload)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance
        .post('addImageToLibrary/imageToLibrary', pLoad)
        .then((response) => {
          console.log('addImageToLibrary returned!')
          console.log(response)
          let responseData = response.data || {}
          if (responseData.success) {
            this.getPictureGalleries().then((res) => {
              console.log('SAVE IMAGE TO LIBRARY - GET PICTURE GALLERY (%j)', res)
              this.activepicturegallery = res.data
              resolve({
                success: true,
                data: response.data
              })
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },

  async storeImagesToMemoryGame(payload) {
    console.log('storeImagesToMemoryGame ')
    console.log(payload)
    let uStore = useUserStore()
    let pUser = uStore.primaryuser
    return new Promise((resolve) => {
      const adminStore = useAdminStore()
      let load = {
        memoryGameImages: payload,
        primaryUserUid: pUser.uid
      }
      let pLoad = {
        mutate: encodeLoad(JSON.stringify(load)),
        p1: adminStore.sid,
        gogl: true
      }
      const instance = adminStore.getAxiosConnection('memas/')
      instance
        .post('addImagesMemoryGame/storeImagesToMemoryGame', pLoad)
        .then((response) => {
          console.log('addImageToLibrary returned!')
          console.log(response)
          let responseData = response.data || {}
          if (responseData.success) {
            this.getPictureGalleries().then((res) => {
              console.log('SAVE IMAGE TO LIBRARY - GET PICTURE GALLERY (%j)', res)
              this.activepicturegallery = res.data
              resolve({
                success: true,
                data: response.data
              })
            })
          }
        })
        .catch((error) => {
          console.log({error})
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  setImageProperties(payload) {
    console.log('setImage Properties (%j)', payload)
    this.mimeType = payload.mimeType
    this.fileName = payload.fileName
  },
  setVideoProperties(payload) {
    console.log('setVideoProperties Properties (%j)', payload)
    this.videoMimeType = payload.mimeType
    this.videoFileName = payload.fileName
  },
  getImageUrl(imageid) {
    let adminStore = useAdminStore()
    if (imageid) {
      return `${adminStore.serverurl}imagedownload/image/${imageid}`
    } else {
      return ''
    }
  },
  getVideoUrl(imageid) {
    let adminStore = useAdminStore()
    return `${adminStore.serverurl}imagedownload/video/${imageid}`
  },
  async deleteGoogleBucketFile(filename) {
    const adminStore = useAdminStore()
    return new Promise((resolve) => {
      if (filename === '') {
        resolve({success: true, msg: 'noFilename specified'})
      }
      let pLoad = {
        fileName: encodeLoad(filename),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('imageupload/')
      instance
        .post('delete', pLoad)
        .then((result) => {
          console.log('delete FILE FROM BUCKET returned ', result)
          resolve({
            success: true,
            data: result
          })
        })
        .catch((error) => {
          console.log('*** ERROR *** delete primary had some problems..')
          console.log(error)
          resolve({
            success: false,
            error: true,
            errorMsg: error.toString()
          })
        })
    }) // promise
  },
  async getPictureGalleries() {
    console.log('pictureGalleries user got called ')
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    let pUser = userStore.primaryuser
    return new Promise((resolve) => {
      let load = `{
        query(func: uid(${pUser.uid})) {
           uid
           pu_hasImageLibrary {
            uid
            il_title
            il_hasImages {
              uid
              image_description
              image_imageurl
            }
           }
        }
    } `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('dquery/pictureGalleries', pLoad)
        .then((response) => {
          console.log('pictureGalleries user respons (%j)', response)
          resolve({success: true, data: response.data.dgraphresult})
        })
        .catch((err) => {
          console.log('** ERROR ** pictureGalleries user respons (%j)', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  async getMemoryGameImages() {
   console.log('getMemoryGameImages  got called ')
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    let pUser = userStore.primaryuser
    return new Promise((resolve) => {
      let load = `{
      var(func: uid(${pUser.uid})) {
         pu_hasImageLibrary {
			  	il_hasImages {
	  		  	imageUid as uid
          }
        }
      }
  	    query(func: uid(imageUid)) {
			  uid
        image_description
        image_imageurl
        }
      }  `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('dquery/getMemoryGameImages', pLoad)
        .then((response) => {
          console.log('getMemoryGameImages  respons (%j)', response)
          resolve({success: true, data: response.data.dgraphresult})
        })
        .catch((err) => {
          console.log('** ERROR ** getMemoryGameImages  respons (%j)', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
   async getImagesToMemoryGame() {
   console.log('getImagesToMemoryGame got called ')
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    let pUser = userStore.primaryuser
    return new Promise((resolve) => {
      let load = `{
      query(func: uid(${pUser.uid})) {
         pu_hasBrainMemoryGameImages {
			  	image_description
          image_imageurl
          }
        }
      }  `
      let pLoad = {
        query: encodeLoad(load),
        p1: adminStore.sid,
        gogl: true
      }
      let instance = adminStore.getAxiosConnection('dgraph-api/')
      instance
        .post('dquery/getImagesToMemoryGame', pLoad)
        .then((response) => {
          console.log('getImagesToMemoryGame respons (%j)', response)
          resolve({success: true, data: response.data.dgraphresult[0]})
        })
        .catch((err) => {
          console.log('** ERROR ** getImagesToMemoryGame user respons (%j)', err)
          resolve({
            success: false,
            error: true,
            errMsg: err
          })
        }) // then
    }) // promise
  },
  getActiveLibrary() {
    return this.activelibrary
  },
  setActiveLibrary(libraryUid) {
    this.activelibrary = libraryUid
  }
  /* ************************************************************** */
  /* PICTURES */
  /* ************************************************************** */
 
}


function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ab], { type: mimeString })

  return blob
}

function convertDataURIToBinary(dataURI) {
  console.log('CONVERT DATA URI TO BINARY ')
  console.log(dataURI)
  var BASE64_MARKER = ';base64,'
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  console.log('CONVERT DATA URI TO BINARY (%s)', base64Index)
  var base64 = dataURI.substring(base64Index)
  console.log('CONVERT DATA URI TO BINARY ')
  console.log(base64)
  var raw = window.atob(base64)
  var rawLength = raw.length
  var array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return array
}
