// import { ref, computed } from "vue";
import { defineStore } from 'pinia'

import { imageState } from './imagestore/imageState.js'
import { imageGetters } from './imagestore/imageGetters.js'
import { imageActions } from './imagestore/imageActions.js'

export const imageStore = defineStore('imageStore', {
  state: () => imageState,
  getters: imageGetters,
  actions: imageActions
})
