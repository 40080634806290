export const adminGetters = {
  getTest(state) {
    return state.test
  },
  getActiveNavItem: (state) => state.activenavitem,
  getUiLevel(state) {
    return state.uilevel
  },
  getLang(state) {
    return state.lang
  },
  getServername(state) {
    return state.servername
  },
  getServerUrl(state) {
    return state.serverurl
  },
  getSid(state) {
    return state.sid
  },
  getPublicKey(state) {
    return state.publickey
  },
  getAppSecret(state) {
    return state.appsecret
  },
  getLanguageList() {
    return [
      {
        label: 'Norwegian',
        lang: 'nb',
        flag: 'nbFlag'
      },
      {
        label: 'English',
        lang: 'en',
        flag: 'enFlag'
      },
      {
        label: 'Italien',
        lang: 'it',
        flag: 'itFlag'
      },
      {
        label: 'Français',
        lang: 'fr',
        flag: 'frFlag'
      },
      {
        label: 'Romanian',
        lang: 'ro',
        flag: 'roFlag'
      }
      /*
       {
        label: 'Swedish',
        lang: 'se',
        flag: 'seFlag'
      },
      {
        label: 'French',
        lang: 'fr',
        flag: 'frFlag'
      },
      {
        label: 'German',
        lang: 'de',
        flag: 'deFlag'
      },
      {
        label: 'Swiss-French',
        lang: 'fr-ch',
        flag: 'chFlag'
      }
      */
    ]
  },
  /* Should be the same i both adminweb and user*/
  getServices() {
    return [
      {
        label: 'today',
        value: 'services.today', // is mapped $t('services.today')
        tooltip: 'services.todaytooltip',
        default: true
      },
      {
        label: 'selfreport',
        value: 'services.selfreport',
        tooltip: 'services.selfreporttooltip',
        default: false
      },
      {
        label: 'brain',
        value: 'services.brain',
        tooltip: 'services.braintooltip',
        default: false
      },
      {
        label: 'dailyhelp',
        value: 'services.dailyhelp',
        tooltip: 'services.dailyhelptooltip',
        default: false
      },
      {
        label: 'entertainment',
        value: 'services.entertainment',
        tooltip: 'services.entertainmenttooltip',
        default: false
      },
      {
        label: 'contact',
        value: 'services.contact',
        tooltip: 'services.contacttooltip',
        default: true
      },
      {
        label: 'workhelp',
        value: 'services.workhelp',
        tooltip: 'services.workhelptooltip',
        default: false
      },
      {
        label: 'phoneweekcalendar',
        value: 'services.phoneweekcalendar',
        tooltip: 'services.phoneweekcalendartooltip',
        default: false
      },
      {
        label: 'advice',
        value: 'services.advice',
        tooltip: 'services.advicetooltip',
        default: false
      },
      {
        label: 'rehab',
        value: 'services.rehab',
        tooltip: 'services.rehabtooltip',
        default: false
      }
      /*
      {
        label: 'blodpressure',
        value: 'services.blodpressure',
        tooltip: 'services.blodpressuretooltip',
        default: false
      },
      {
        label: 'cognitiveloss',
        value: 'services.cognitiveloss',
        tooltip: 'services.cognitivelosstooltip',
        default: false
      },
      {
        label: 'diabetes1',
        value: 'services.diabetes1',
        tooltip: 'services.diabetes1tooltip',
        default: false
      },
      {
        label: 'diabetes2',
        value: 'services.diabetes2',
        tooltip: 'services.diabetes2tooltip',
        default: false
      },
      {
        label: 'dialog',
        value: 'services.dialog',
        tooltip: 'services.dialogtooltip',
        default: false
      },
      {
        label: 'elearning',
        value: 'services.elearning',
        tooltip: 'services.elearningtooltip',
        default: false
      },
      {
        label: 'exercise',
        value: 'services.exercise',
        tooltip: 'services.exercisetooltip',
        default: false
      },
      {
        label: 'heartfailure',
        value: 'services.heartfailure',
        tooltip: 'services.heartfailuretooltip',
        default: false
      },
      {
        label: 'knowledge',
        value: 'services.knowledge',
        tooltip: 'services.knowledgetooltip',
        default: false
      },
      {
        label: 'kols',
        value: 'services.kols',
        tooltip: 'services.kolstooltip',
        default: false
      },
      {
        label: 'livinghelp',
        value: 'services.livinghelp',
        tooltip: 'services.livinghelptooltip',
        default: false
      },
      {
        label: 'nutrition',
        value: 'services.nutrition',
        tooltip: 'services.nutritiontooltip',
        default: false
      },
      {
        label: 'obesity',
        value: 'services.obesity',
        tooltip: 'services.obesitytooltip',
        default: false
      },
      {
        label: 'schoolhelp',
        value: 'services.schoolhelp',
        tooltip: 'services.schoolhelptooltip',
        default: false
      },
      {
        label: 'smartwatch',
        value: 'services.smartwatch',
        tooltip: 'services.smartwatchtooltip',
        default: false
      },
      {
        label: 'status',
        value: 'services.status',
        tooltip: 'services.statustooltip',
        default: false
      }
      */
    ]
  },
  getEngageActivityData(state) {
    return state.engageActivityData
  }
}
